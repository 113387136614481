/**
 * 存储会话
 */
import { getMessageList } from "@/api/index.js";
const state = {
    messageListValue: [],
    activeId: null,
    // 执行一次标志
    isOnce: false,
    // 提示词是否显示
    isShowAgent: true,
    // 存储上传文件id
    uploadFileId: [],
    // 存储上传文件 -- 页面展示
    uploadFileURL: [],
    // 上传按钮是否显示
    isShowUpload: false,
    // 存储智谱清言子模型id
    assistantId: null,
    // 模型id
    botId: "",
    // 第三方id
    conversationId: '',
    // 右侧加载标识CLEAR_MESSAGE_TIMER
    isShowLoading: false,
    // 文本输出标识
    isShowText: false,
    // 存储是否是音乐
    isMusic: false,
    // 定时器
    messageTimer: null,
    // 结束标识
    isEnd: false,
    // 录音中标识
    isRecording: false,
    // 终止音频标识
    isVoidStop: false,
    // // 音乐加载状态
    // isMusicLoading: false,
}
// 修改state数据
const mutations = {
    // 修改执行一次标识
    CHANGE_IS_ONCE(state, isOnce) {
        state.isOnce = isOnce
    },
    // 修改录音中标识
    CHANGE_IS_RECORDING(state, isRecording) {
        state.isRecording = isRecording
    },
    // 修改结束标识
    CHANGE_IS_END(state, isEnd) {
        state.isEnd = isEnd
    },
    CHANGE_ACTIVE_ID(state, id = '') {
        console.log(id, 'ididididididididididididid');
        state.activeId = id
    },
    // 修改上传按钮状态
    CHANGE_IS_SHOW_UPLOAD(state, isShowUpload) {
        state.isShowUpload = isShowUpload
    },
    // 修改智谱清言子模型id
    CHANGE_ASSISTANT_ID(state, assistantId = '') {
        state.assistantId = assistantId
    },
    // 修改模型id
    CHANGE_BOT_ID(state, botId = "") {
        state.botId = botId
    },
    // 修改第三方id
    CHANGE_CONVERSATION_ID(state, conversationId = "") {
        console.log(conversationId, '第三方conversationId');
        state.conversationId = conversationId
    },
    // 修改是否是音乐
    CHANGE_IS_MUSIC(state, isMusic) {
        state.isMusic = isMusic
    },
    CHANGE_IS_SHOW_AGENT(state, isShowAgent) {
        state.isShowAgent = isShowAgent
    },
    Message_List(state, list) {
        state.messageListValue = list
        if (list.length >= 0) {
            state.isShowLoading = false
        }
    },
    SET_Message_List(state, list) {
        console.log(list, 'listlistlistlist');
        // if (list.content == '加载中') {
        //     console.log('加载中');
        //     state.isLoading = true
        // }
        state.messageListValue.push(list);
        state.isShowAgent = false
        state.CHANGE_IS_SHOW_TEXT = false
    },
    CHANGE_Message_List(state, list) {
        console.log(list, 'list----------');
        state.messageListValue[state.messageListValue.length - 1].content = list.result;
        state.messageListValue[state.messageListValue.length - 1].image = list.image;

    },
    // 存储/修改上传文件id
    CHANGE_UPLOAD_FILE_ID(state, list) {
        console.log(list.length, '下list下list下list下list');
        if (list.length <= 0) {
            console.log('00000000000');
            state.uploadFileId = []
            state.uploadFileURL = []
            return;
        }
        state.uploadFileId = list.map((item) => {
            return item.file_id
        })
        state.uploadFileURL = list.map((item) => {
            return item.file_url
        })
    },
    // 更改右侧加载标识
    CHANGE_IS_SHOW_LOADING(state, isShowLoading) {
        state.isShowLoading = isShowLoading
    },
    // 更改文本输出标识
    CHANGE_IS_SHOW_TEXT(state, isShowText) {
        state.isShowText = isShowText
    },
    // 开启setInterval 每2秒调用一次
    CLEAR_MESSAGE_TIMER(state) {
        if (state.messageTimer) {
            clearInterval(state.messageTimer);
            state.isEnd = false;
            state.messageTimer = null;
            state.isVoidStop = true

        }
    },
    // 添加一个 mutation 来设置定时器 ID
    SET_MESSAGE_TIMER(state, timerId) {
        state.messageTimer = timerId;
    },
}
// 处理异步
const actions = {
    async handleGetMessageList({ commit }, data,) {
        let res = await getMessageList(data);
        if (res.success) {
            let _list = res.pageInfo.list.map((item) => {
                let content = '加载中'; // 默认设置为“加载中”
                console.log(item, 'itemitemtiem-------------');
                // 检查 item.content 是否包含 'audio_url'
                if (typeof item.content === 'string' && item.content.includes('https://cdn1.suno.ai')) {
                    console.log('清除定时器');
                    commit('CLEAR_MESSAGE_TIMER'); // 如果包含，则清除定时器
                    content = item.content; // 设置 content 为 item.content
                } else {
                }

                if (item.content?.includes('prompt')) {
                    // 尝试将 item.content 解析为对象
                    content = item.content ? JSON.parse(item.content) : {};
                } else {
                    content = item.content
                }

                // 确保 images 字段是数组格式
                // let images = Array.isArray(item.images) ? item.images : [item.images];
                console.log(item.images, 'item.images');

                return {
                    ...item,
                    content,
                    image: JSON.parse(item.images),// 删除前后的引号
                };
            });
            console.log(_list, 'list----------');
            commit('Message_List', _list) // 提交处理后的列表
        }
    },
    startMessageTimer({ commit, dispatch }, data) {
        commit('CLEAR_MESSAGE_TIMER'); // 确保先清除旧的定时器
        const timerId = setInterval(() => {
            dispatch('handleGetMessageList', data,); // 每 2 秒调用一次 
        }, 2000);
        commit('SET_MESSAGE_TIMER', timerId); // 存储新的定时器 ID
    },

}

const getters = {
}
export default {
    state,
    getters,
    mutations,
    actions
}