import request from '@/utlis/request.js'
/**
 * 跟据id查询对话
 * @param {*} id 
 * @returns 
 */
export function getHistoryId(params) {
    return request({
        url: '/api/history',
        method: 'GET',
        params
    })
}

/**
 * 条件分页查询对话
 * @param {*} data 
 * @returns 
 */
export function historyList(data) {
    return request({
        url: '/api/history/list',
        method: 'POST',
        data
    })
}

/**
 * 删除对话
 * @param {*} params 
 * @returns 
 */
export function getHistoryDelete(params) {
    return request({
        url: '/api/history/delete',
        method: 'POST',
        params
    })
}

export function getHistoryUpdate(data) {
    return request({
        url: '/api/history/update',
        method: 'POST',
        data
    })
}
