/**
 * 左侧侧边栏
 */
import { historyList } from "@/api/dialogueMessage.js";
const state = {
    ListValue: [],
    loading: false,
    AgentComList: {},
    // 存储语音返回的数据
    audioValue: "",
    // 音频解析中字段
    audioStatus: false,
    // 存储音频的数据
}
// 修改state数据
const mutations = {
    SET_LISTVALUE(state, data) {
        state.audioStatus = data
    },
    // 存储语音返回的数据
    SET_AUDIOVALUE(state, data) {
        console.log(data, 'data');
        state.audioValue = data
    },
    GET_LISTVALUE(state, data) {
        state.ListValue = data
        if (state.ListValue.length >= 0) {
            state.loading = false;
        }
    },
    SET_LOADING(state, data) {
        state.loading = data
    },
    // 修改智能体提示数据
    SET_AgentCom(state, data) {
        if (Object.keys(data).length === 0) {
            state.AgentComList = {}
            return;
        }
        state.AgentComList = {
            ...data,
            userContent: data.userContent.split(';')
        }
    },

}
// 处理异步
const actions = {
    async handleHistoryId({ commit }) {
        commit('SET_LOADING', true)
        let res = await historyList({
            title: "", //标题
            currentPage: 1, //当前页
            pageSize: 99999, //每页显示条数
        });
        if (res.success) {
            commit('GET_LISTVALUE', res.pageInfo.list)
        }
    },
}
const getters = {
}
export default {
    state,
    getters,
    mutations,
    actions
}