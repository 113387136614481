import { createStore } from 'vuex'

import message from './message/index.js'
import agentCom from './agentCom/index.js'
export default createStore({
  modules: {
    message,
    agentCom
  }
})
