// 导入 Vue Router 的两个函数，用于创建路由器和历史记录
import { createRouter, createWebHistory } from 'vue-router'

// 定义路由数组，包含应用的各个路由配置
const routes = [
  {
    // 路由重定向配置
    path: '/', // 当访问根路径时
    redirect: { path: '/home', query: { type: '0' } } // 将重定向到 '/Home' 路径
  },
  {
    path: '/home', // 定义一个动态路径参数 ':type'
    name: 'Home', // 路由的名称
    component: () => import('@/views/Home.vue'), // 按需加载的组件，指向 Home.vue 视图组件
    // 在路由元信息中设置默认的查询参数
    meta: { defaultQuery: { type: '1' } }
  }
]

// 创建路由器实例，传入历史记录配置和路由配置
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // 使用 HTML5 的历史记录模式，并基于环境变量中的基础URL
  routes // 导入上面定义的路由数组
})

// 将路由器实例导出为默认模块
export default router
