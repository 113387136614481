import request from "@/utlis/request.js";
/**
 * 条件分页获取消息
 * @returns 
 * 
 */
export function getMessageList(data) {
    return request({
        url: '/api/massage/list',
        method: 'POST',
        data
    })
}

/**
 * 文件上传
 * 显示图片
 */
export function uploadFile(data, access_token) {
    return request({
        url: '/chatglm/backend-api/assistant/file_upload',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + `${access_token}`
        }
    })
}
/**
 * 文件id
 * @param {*} data 
 * @returns 
 */
export function uploadFileGetId(data, access_token) {
    console.log(access_token, 'access_token');
    return request({
        url: '/chatglm/assistant-api/v1/file_upload',
        method: 'POST',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + `${access_token}`
        }
    })
}

/**
 * 获取api_key
 */
export function getApiKey(data) {
    return request({
        url: '/chatglm/assistant-api/v1/get_token',
        method: 'POST',
        data,
    })
}



/**
 * 
 * @param {*} data 
 * @returns 
 */

export function FileMusicUpload(data) {
    return request({
        url: '/music/voice',
        method: 'POST',
        data,
    })
}

/**
 * 停止会话
 * @param {*} data 
 * @returns 
 */
export function massageStop(data = {}) {
    return request({
        url: '/api/massage/stop',
        method: 'POST',
        data,
    })
}